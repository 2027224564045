import React, { Component, useState } from 'react';
import Amplify, { Storage, API, graphqlOperation, Auth } from 'aws-amplify';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import awsmobile from './aws-exports';
import { ConfirmSignUp, ForgotPassword, SignIn, SignUp, withAuthenticator } from 'aws-amplify-react';
import moment from 'moment';
import './App.css';
import Menu from './sections/Menu';
import NewOrder from './sections/NewOrder';
import OrdersClosed from './components/OrdersClosed';
import {
  PRIMARY_COLOR,
} from './theme/colors';

const apiKey = process.env.REACT_APP_API_KEY;

Amplify.configure(awsmobile);
Amplify.configure({
  Storage: {
    AWSS3: {
        bucket: 'different-blend-bakery-menu-photos',
        region: 'us-east-1',
    }
  },
  "aws_appsync_graphqlEndpoint": "https://unehcovujjfo5phj2iay3quh24.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": apiKey,
  // API: {
  //   aws_appsync_graphqlEndpoint: "https://kpjuswyvtzakrmomqdtyflxisi.appsync-api.us-east-1.amazonaws.com/graphql",
  //   aws_appsync_region: "us-east-1",
  //   aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  //   aws_appsync_apiKey: null,
  // }
});
Storage.configure({ level: 'public' });

const theme = createMuiTheme({
  palette: {
    primary: { main: PRIMARY_COLOR}, // Purple and green play nicely together. Christmas: #0d8515, Thanksgiving: '#A57300', Easter: '#a364ac' - TG2: #CE9000, #A57300, #536C02
    secondary: { main: '#11cb5f' }, // This is just green.A700 as hex.
    error: { main: '#ff0000' }
  },
  typography: { useNextVariants: true, fontFamily: ['Raleway'] },
});

const getOrderDates = `query listOrderDates {
  listOrderDates{
    items{
      id
      tag
      date
    }
  }
}`;

class MyConfirmSignup extends ConfirmSignUp {
  constructor(props) {
    super(props);
    this._validAuthStates = ["confirmSignUp"];
  }

  render() {
    if (this.props.authState === "confirmSignUp") {
      return (
      <div style={{display: 'flex'}}>
      <Card style={{ minWidth: 275 }}>
        <CardContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <img src={'/images/DifferentBlendBakeryLogo.jpg'} alt={'Oops logo not found'} style={{width: '40%', marginTop: '1rem' }}/>
          <Typography variant="h5" component="h2" style={{marginTop: '1rem'}}>
              A verification link has been sent to the provided email address
          </Typography>
          <Typography align='center' color='primary' variant="caption" gutterBottom>
              Be sure to check your spam folder if you don't see an email within a few minutes
          </Typography>
        </CardContent>
      </Card>
      </div>);
    } else {
      return (null);
    }
  }
}

class App extends Component {
  // const [accessCount, setAccessCount] = useState(0);
  state = {
    ordersStartDate: new Date(),
    ordersStartDateString: '',
    ordersCutoffDate: new Date(),
    ordersCutoffDateString: '',
    onlineOrdersStartDate: new Date(),
    onlineOrdersStartDateString: '',
    onlineOrdersCutoffDate: new Date(),
    onlineOrdersCutoffDateString: '',
    allowAccess: false,
  }
  ordersMomentStartDate = moment();
  ordersMomentEndDate = moment();
  onlineOrdersMomentStartDate = moment();
  onlineOrdersMomentEndDate = moment();
  today = moment();

  async componentDidMount() {
    const orderDates = await API.graphql(graphqlOperation(getOrderDates));
    if (orderDates.data.listOrderDates.items.length > 0) {
      let ordersStartDate = '';
      let ordersCutoffDate = '';
      let onlineOrdersStartDate = '';
      let onlineOrdersCutoffDate = '';
      orderDates.data.listOrderDates.items.forEach(orderDatesItem => {
        if (orderDatesItem.tag === 'ordersStartDate') {
          ordersStartDate = orderDatesItem.date;
          this.ordersMomentStartDate = moment(orderDatesItem.date);
        } else if (orderDatesItem.tag === 'ordersCutoffDate') {
          ordersCutoffDate = orderDatesItem.date;
          this.ordersMomentEndDate = moment(orderDatesItem.date);
        } else if (orderDatesItem.tag === 'onlineOrdersStartDate') {
          onlineOrdersStartDate = orderDatesItem.date;
          this.onlineOrdersMomentStartDate = moment(orderDatesItem.date);
        } else if (orderDatesItem.tag === 'onlineOrdersCutoffDate') {
          onlineOrdersCutoffDate = orderDatesItem.date;
          this.onlineOrdersMomentEndDate = moment(orderDatesItem.date);
        }
      });
      if (ordersStartDate !== '' && ordersCutoffDate !== '') {
        this.setState({ordersStartDate, ordersCutoffDate});
      } else if (ordersStartDate !== '') {
        this.setState({ordersStartDate});
      } else {
        this.setState({ordersCutoffDate});
      }
      if (onlineOrdersStartDate !== '' && onlineOrdersCutoffDate !== '') {
        this.setState({onlineOrdersStartDate, onlineOrdersCutoffDate});
      } else if (onlineOrdersStartDate !== '') {
        this.setState({onlineOrdersStartDate});
      } else {
        this.setState({onlineOrdersCutoffDate});
      }
    }
  }

  renderOrdersClosed() {
    if (!this.today.isBetween(this.onlineOrdersMomentStartDate, this.onlineOrdersMomentEndDate, null, '[]') && !this.state.allowAccess) {
      if (this.today.isBefore(this.onlineOrdersMomentStartDate)) {
        return (<OrdersClosed beforeOrAfter={'before'} onlineOrdersStart={this.onlineOrdersMomentStartDate} onlineOrdersEnd={this.onlineOrdersMomentEndDate}/>);
      } else {
        return (<OrdersClosed beforeOrAfter={'after'} onlineOrdersStart={this.onlineOrdersMomentStartDate} onlineOrdersEnd={this.onlineOrdersMomentEndDate}/>);
      }
    }
    return null;
  }

  render() {
    return (
      <div className="App">
        <MuiThemeProvider theme={theme}>
          {this.renderOrdersClosed()}
          <div style={{width: '65%', backgroundColor: '#FFFFFF', margin: 'auto'}}>
            <img src={'/images/DifferentBlendBakeryLogo.jpg'} alt={'Oops logo not found'} style={{width: '40%', marginTop: '1rem' }}/>
            <Menu ordersEndedDate={this.onlineOrdersMomentEndDate}/>
            <NewOrder ordersStartDate={this.state.ordersStartDate} ordersCutoffDate={this.state.ordersCutoffDate} />
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;

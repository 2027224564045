import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import {
  getMonthLabel,
  getNumberPostfix,
  getDayLabel
} from '../utils/time';

const styles = theme => ({
  OrdersClosedStyle: {
    display: 'flex', 
    flexDirection: 'column', 
    marginLeft: 10,
    marginRight: 10,
    alignItems: 'center'
  },
  ImageSeparator: {
    height: '1rem'
  },
});

const now = moment();
let ordersCutoff = moment({ year :2019, month :10, day :23, 
    hour :17, minute :0, second :0, millisecond :0});

class OrdersClosed extends React.Component {

  state = {
    open: false,
  }

  areOrdersClosed() {
    if (now.isAfter(ordersCutoff) || this.props.beforeOrAfter === 'before') {
        return true;
    }
    return false;
  }
  
  renderOrdersClosedMessage(onlineOrdersStart, onlineOrdersEnd) {
    const currentMonth = now.month();
    console.log({currentMonth});
    let holiday = '';
    if (currentMonth === 11) {
      holiday = 'Christmas';
    } else if ([9, 10].includes(currentMonth)) {
      holiday = 'Thanksgiving';
    } else if ([1, 2, 3].includes(currentMonth)) {
      holiday = 'Easter';
    }
    const onlineOrdersStartMonth = onlineOrdersStart.month();
    const onlineOrdersEndMonth = onlineOrdersEnd.month();
    let startMonthLabel = getMonthLabel(onlineOrdersStartMonth);
    let endMonthLabel = getMonthLabel(onlineOrdersEndMonth);

    console.log({currentMonth});
    if (this.props.beforeOrAfter === 'before') {
      return (
        <Typography align='center' color='primary' variant="h6" gutterBottom>
            {`${holiday} pre-orders will start ${getDayLabel(onlineOrdersStart.day())}, ${startMonthLabel} ${onlineOrdersStart.date()}${getNumberPostfix(onlineOrdersStart.date())} and go until ${onlineOrdersEnd.format('h')}pm on ${endMonthLabel} ${onlineOrdersEnd.date()}${getNumberPostfix(onlineOrdersEnd.date())}`}
        </Typography>
      );
    }
    return (
      <Typography align='left' color='primary' variant="h6" gutterBottom>
          {`Unfortunately ${holiday} pre-orders are no longer being taken as of ${onlineOrdersEnd.local().format('h')}pm on ${endMonthLabel} ${onlineOrdersEnd.date()}${getNumberPostfix(onlineOrdersEnd.date())}`}
      </Typography>
    );
  }

  render() {
    const { classes, onlineOrdersStart, onlineOrdersEnd } = this.props;
    return (
        <Dialog
          open={this.areOrdersClosed()}
          fullWidth={true}
          maxWidth='md'
        >
        <div className={classes.OrdersClosedStyle}>
        <img src={'/images/DifferentBlendBakeryLogo.jpg'} alt={'Oops logo not found'} style={{width: '40%', marginTop: '1rem' }}/>
        <div className={classes.ImageSeparator}/>
        <Typography align='left' color='primary' variant="h5" gutterBottom>
            Thanks for visiting Different Blend Bakery online orders
        </Typography>
        {this.renderOrdersClosedMessage(onlineOrdersStart, onlineOrdersEnd)}
        {/*<Typography align='left' color='primary' variant="body2" gutterBottom>
            All bakery products are first come first serve the week of Christmas 12/20-12/24
    </Typography>*/}
        <div className={classes.ImageSeparator}/>
      </div>
        </Dialog>

    );
  }
} // end OrdersClosed

OrdersClosed.propTypes = {
  classes: PropTypes.object.isRequired,
  onlineOrdersStart: PropTypes.object.isRequired,
  onlineOrdersEnd: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrdersClosed);
import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import OrderItem from '../components/OrderItem';
import { updateOrderItems } from '../actions/index';
import {
  PRIMARY_COLOR,
} from '../theme/colors';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  ItemImage: {
    width: 256,
    height: 170
  },
  titleSection: {
    marginLeft: 12,
    marginBottom: 4,
    marginTop: 30
  },
  dividerStyle: {
    background: PRIMARY_COLOR,
    height: 2,
  },
  itemDividerStyle: {
    height: 1,
  },
  newMenuItem: {
    marginTop: 15,
    marginLeft: 14,
    float: 'left'
  },
  MenuSection: {
    display: 'flex', 
    flexDirection: 'column', 
    marginLeft: 10,
    marginRight: 10,
  },
  OrderItemSeparator: {
    height: '1rem'
  },
  newOrder: {
      marginBottom: '1rem'
  },
  PickupDateStyle: {
      marginTop: '1rem'
  },
  LastNameStyle: {
    marginLeft: '1rem'
  },
  TotalStyle: {
    marginTop: '1rem',
    marginLeft: '1rem'
  },
  ReviewContactStyle: {
    marginTop: '1rem',
    marginLeft: '1rem',
    fontWeight: '700'
  }
});

const createOrder = `mutation createOrder($name:String! $firstName:String! $lastName:String! $email:String! $phone:String! $pickupDate:String! $pickupWindow:String $pickupWindowId:ID $orderPlacedDate:String! $orderItems:[OrderItemInput!]! $extraInfo:String $orderStatus:String! $orderSubtotal:String! $orderTax:String! $orderTotal:String! $prepaid:Boolean!) {
    createOrder(input:{
      name:$name
      firstName:$firstName
      lastName:$lastName
      email:$email
      phone:$phone
      pickupDate:$pickupDate
      pickupWindow:$pickupWindow
      pickupWindowId:$pickupWindowId
      orderPlacedDate:$orderPlacedDate
      orderItems:$orderItems
      extraInfo:$extraInfo
      orderStatus:$orderStatus
      orderSubtotal:$orderSubtotal
      orderTax:$orderTax
      orderTotal:$orderTotal
      prepaid:$prepaid
    }) {
      id
      name
      firstName
      lastName
      email
      phone
      pickupDate
      pickupWindow
      pickupWindowId
      orderPlacedDate
      orderItems {
        item
        quantity
        size
        isDairyFree
        ganacheTopping
      }
      extraInfo
      orderStatus
      orderSubtotal
      orderTax
      orderTotal
      prepaid
    }
  }`;

  const listPickupWindows = /* GraphQL */ `
  query ListPickupWindows($limit: Int) {
    listPickupWindows(limit: $limit) {
      items {
        id
        windowStart
        windowEnd
        date
        availableSpots
        totalSpots
      }
      nextToken
    }
  }
`;


function getSteps() {
    return ['Order Information', 'Review Your Order', 'Confirmation'];
  };

  const quantities = [{ value: 0, label: '0' }, { value: 1, label: '1' }, { value: 2, label: '2' },
  { value: 3, label: '3' }, { value: 4, label: '4' }, { value: 5, label: '5' }, { value: 6, label: '6' },
  { value: 7, label: '7' }, { value: 8, label: '8' }, { value: 9, label: '9' }, { value: 10, label: '10' }];

class NewOrder extends React.Component {

  state = {
    open: false,
    activeStep: 0,
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    emailFormatError: false,
    phone: '',
    pickupDate: null,
    pickupWindows: null,
    pickupWindowDisplayList: [],
    submitDisabled: false,
  }

  async componentDidMount() {
    // console.log({listPickupWindows});
    // const pickupWindows = await API.graphql(graphqlOperation(listPickupWindows, {limit: 100}));
    // console.log(pickupWindows);
    // const pickupWindowsList = [];

    // pickupWindows.data.listPickupWindows.items.forEach(pickupWindowItem => {
    //   pickupWindowsList.push(pickupWindowItem);
    // });

    // // sort the pickup windows by start time
    // pickupWindowsList.sort((a, b) => {
    //   return a.windowStart > b.windowStart ? 1 : -1;
    // });

    // const dateList = pickupWindowsList.map((pickupWindowItem) => pickupWindowItem.date).filter((value, index, self) => self.indexOf(value) === index)

    // const pickupWindowDisplayList = dateList.map((dateListItem) => {
    //   return {
    //     date: moment(dateListItem).date(),
    //     pickupWindows: [],
    //   };
    // });

    // pickupWindowsList.forEach((pickupWindowItem) => {
    //   const windowTimeStart = moment.unix(pickupWindowItem.windowStart).format("h:mmA");
    //   const windowTimeEnd = moment.unix(pickupWindowItem.windowEnd).format("h:mmA");
    //   const date = moment(pickupWindowItem.date).date();
    //   const windowIndex = pickupWindowDisplayList.findIndex((pickupWindowDisplayItem) => {
    //     return pickupWindowDisplayItem.date === date;
    //   });
    //   pickupWindowDisplayList[windowIndex].pickupWindows.push({
    //     pickupWindowId: pickupWindowItem.id,
    //     timeWindow: `${windowTimeStart}-${windowTimeEnd}`,
    //     available: pickupWindowItem.availableSpots > 0,
    //     day: moment.unix(pickupWindowItem.windowStart).date(),
    //   });
    // });
    // console.log({pickupWindowDisplayList});
    // this.setState({ pickupWindowDisplayList });
  }

  validateEmailFormat = () => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email);
  }

  handleSubmitOrder = async () => {
    this.setState({ submitDisabled: true});
    const orderPlacedDate = new Date();
    const pickupDate = new Date(this.state.pickupDate);
    let createOrderDetails = {
      name: `${this.state.firstName} ${this.state.lastName}`,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      pickupDate: `${pickupDate.getMonth()+1}/${pickupDate.getDate()}/${pickupDate.getFullYear()}`,
      orderPlacedDate: orderPlacedDate.toLocaleString(),
      orderItems: this.props.orderItems,
      extraInfo: 'EMPTY',
      orderStatus: 'ordered',
      orderSubtotal: `$${this.props.orderSubtotal}`,
      orderTax: `$${this.props.orderTax}`,
      orderTotal: `$${this.props.orderTotal}`,
      prepaid: false,
      employee: 'EMPTY',
    };

    // get the pickup window ID
    // const date = moment(pickupDate).date();
    // const windowIndex = this.state.pickupWindowDisplayList.findIndex((pickupWindowDisplayItem) => {
    //   return pickupWindowDisplayItem.date === date;
    // });
    // const pickupWindowIndex = this.state.pickupWindowDisplayList[windowIndex].pickupWindows.findIndex((pickupWindowItem) => 
    //   pickupWindowItem.timeWindow === this.state.pickupWindow
    // );
    //console.log({createOrderDetails});
    // const pickupWindowId = this.state.pickupWindowDisplayList[windowIndex].pickupWindows[pickupWindowIndex].pickupWindowId;
    // createOrderDetails.pickupWindow = this.state.pickupWindow;
    // createOrderDetails.pickupWindowId = pickupWindowId;
    // console.log({createOrderDetails});
    try {
      const createOrderResponse = await API.graphql(graphqlOperation(createOrder, createOrderDetails));
      console.log({createOrderResponse});
      this.props.updateOrderItems([]);
      
      this.setState({ activeStep: this.state.activeStep + 2, pickupDate: '', submitDisabled: false});
    } catch (err) {
      console.log({err});
      
    }
  };

  handleOrderInfoChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClickNewOrder = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, activeStep: 0 });
  };

  handlePickupDateChange = date => {
    console.log({date});
    this.setState({
      pickupDate: date
    });
  };

  // handlePickupWindowChange = name => event => {
  //   this.setState({
  //     [name]: event.target.value,
  //   });
  // };

  renderOrderItem(orderItem, style) {
      //console.log({orderItem});
      return (
    <div>
    <div className={style.OrderItemBlock}>
    <div className={style.ItemImage}>
      <img width={'100%'} src={orderItem.imageName}/>
    </div>
    <div className={style.MenuItemInfo}>
    <div className={style.MenuItemTitleAndPrice}>
      <Typography align='left' color='primary' variant="body2" gutterBottom>
        {orderItem.item}
      </Typography>
    </div>
    <div>
    </div>
    <div className={style.QuantityAndSizeSelect}>
    <TextField
      id="quantity-select"
      select
      label="Quantity"
      value={this.state.quantity}
      onChange={this.handleChangeQuantity('quantity')}
      SelectProps={{
        native: true,
      }}
      helperText="Select a quantity"
      margin="normal"
      variant="outlined"
    >
      {quantities.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
    {this.renderSizeSelect(orderItem.pricing)}
    </div>
    </div>
    </div>
  </div>
);
  }

  // renderPickupWindowSelect() {
  //   if (this.state.pickupDate) {
  //     const date = moment(this.state.pickupDate).date();
  //     console.log({date});
  //     console.log(this.state.pickupWindowDisplayList);
  //     const windowIndex = this.state.pickupWindowDisplayList.findIndex((pickupWindowDisplayItem) => {
  //       return pickupWindowDisplayItem.date === date;
  //     });
  //     return (
  //       <TextField
  //         id="pickupWindowSelect"
  //         required
  //         select
  //         margin="normal"
  //         variant="outlined"
  //         label="Pickup Window"
  //         fullWidth
  //         value={this.state.pickupWindow}
  //         onChange={this.handlePickupWindowChange('pickupWindow')}
  //       >
  //       {this.state.pickupWindowDisplayList[windowIndex].pickupWindows.map((pickupWindowDisplayItem) => (
  //         <MenuItem key={pickupWindowDisplayItem.pickupWindowId} value={pickupWindowDisplayItem.timeWindow} disabled={!pickupWindowDisplayItem.available}>
  //           {pickupWindowDisplayItem.timeWindow}
  //         </MenuItem>
  //       ))}
  //       </TextField>
  //     );
  //   }
  //   return null;
  // }

  renderDialogContent() {
      if (this.state.activeStep === 0) {
        return (
        <DialogContent>
            <div>
            <Typography color='primary' align='left' variant="body1" gutterBottom>
                Let's get the rest of your information
            </Typography>
            <TextField
              required
              margin="dense"
              id="firstName"
              label="First Name"
              value={this.state.firstName}
              onChange={this.handleOrderInfoChange('firstName')}
              type="text"
            />
            <TextField
              required
              margin="dense"
              id="lastName"
              label="Last Name"
              value={this.state.lastName}
              onChange={this.handleOrderInfoChange('lastName')}
              type="text"
              className={this.props.classes.LastNameStyle}
            />
            </div>
            <div>
            <TextField
              required
              error={this.state.emailFormatError}
              helperText="Email address is invalid format"
              margin="dense"
              id="email"
              label="Email Address"
              value={this.state.email}
              onChange={this.handleOrderInfoChange('email')}
              type="email"
            />
            </div>
            <div>
            <TextField
              required
              margin="dense"
              id="phone"
              label="Phone Number"
              value={this.state.phone}
              onChange={this.handleOrderInfoChange('phone')}
              type="tel"
            />
            </div>
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
              <DatePicker
                required
                //variant="inline"
                label="Pickup Date"
                value={this.state.pickupDate}
                onChange={this.handlePickupDateChange}
                minDate={this.props.ordersStartDate}
                maxDate={this.props.ordersCutoffDate}
                className={this.props.classes.PickupDateStyle}
              />
            </MuiPickersUtilsProvider>
            {/* this.renderPickupWindowSelect() */}
        </DialogContent>
        );
      } else if (this.state.activeStep === 1) {
          //console.log(this.props.orderItems);
        return (
        <DialogContent>
        <Typography color='primary' align='left' variant="body1" gutterBottom>
            Time to review your order, if you need to make any changes you can make your changes below
        </Typography>
        {this.props.orderItems.map(orderItem => {
          console.log({orderItem});
          return (
            <div key={orderItem.id}>
            <div className={this.props.classes.OrderItemSeparator}/>
            <OrderItem itemInfo={orderItem} />
            <div className={this.props.classes.OrderItemSeparator}/>
            <Divider variant="middle" className={this.props.classes.itemDividerStyle}/>
            </div>
          );
        })}
        <Typography align='left' variant="h6" gutterBottom className={this.props.classes.TotalStyle}>
          Subtotal: ${this.props.orderSubtotal}
        </Typography>
        <Typography align='left' variant="h6" gutterBottom className={this.props.classes.TotalStyle}>
          Tax: ${this.props.orderTax}
        </Typography>
        <Typography align='left' variant="h6" gutterBottom className={this.props.classes.TotalStyle}>
          Payment due at pick up: ${this.props.orderTotal}
        </Typography>
        <Typography align='left' variant="body1" gutterBottom className={this.props.classes.TotalStyle}>
          Payment is required before pick up for orders totalling $150 or more.
        </Typography>
        <Typography align='left' variant="body1" gutterBottom className={this.props.classes.TotalStyle}>
          Any order not picked up after the scheduled pick up day may be cancelled once DBB makes reasonable attempts to contact you. 
        </Typography>
        <Typography align='left' variant="body1" gutterBottom className={this.props.classes.TotalStyle}>
          If DBB is unable to successfully contact you with questions or concerns about your order, it may cancel the order.
        </Typography>
        {/*<Typography align='left' variant="h6" gutterBottom className={this.props.classes.ReviewContactStyle}>
          We'll contact you by phone for prepayment with a credit or debit card
      </Typography>*/}
        </DialogContent>
          );
      } else {
          return (
        <DialogContent>
        <Typography align='center' color='primary' variant="h3" gutterBottom>
            Thanks for your order!
        </Typography>
        {/*<Typography align='center' color='primary' variant="body2" gutterBottom style={{fontWeight: '900'}}>
            We'll contact you by phone for prepayment with a credit or debit card
          </Typography>*/}
        <Typography align='center' color='primary' variant="body2" gutterBottom>
            You'll get an email with your order information shortly
        </Typography>
        <Typography align='center' color='primary' variant="caption" gutterBottom  style={{display: 'flex', flexDirection: 'column'}}>
            Be sure to check your spam folder if you don't see an email within a few minutes
        </Typography>
        </DialogContent>
          );
      }
  }

  handleNext = () => {
    const { activeStep } = this.state;
    if (activeStep === 0 && this.state.pickupDate === '') {
      if (this.validateEmailFormat()) {
        this.setState({
          activeStep: activeStep + 1,
          pickupDate: this.props.ordersStartDate
        });
      } else {
        this.setState({ emailFormatError: true });
      }
    } else {
      if (this.validateEmailFormat()) {
        this.setState({
          activeStep: activeStep + 1,
        });
      } else {
        this.setState({ emailFormatError: true });
      }
    } 
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  isReviewOrderButtonDisabled() {
      if (this.state.pickupWindow === '' || this.state.firstName === '' || this.state.lastName === '' || this.state.email === '' || this.state.phone === '' || this.state.pickupDate === null) {
          return true;
      }
      return false;
  }

  renderDialogActions() {
    if (this.state.activeStep === 0) {
        return (
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={this.handleNext} disabled={this.isReviewOrderButtonDisabled()} color="primary">
              Review Order
            </Button>
          </DialogActions>
        );
      } else if (this.state.activeStep === 1) {
        return (
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={this.handleBack} color="primary">
              Back
            </Button>
            <Button disabled={this.state.submitDisabled} variant="contained" onClick={this.handleSubmitOrder} color="primary">
              Place Order
            </Button>
          </DialogActions>
          );
      } else {
          return (
          null
          );
      }
  }

  isContinueButtonDisabled() {
      if (this.props.orderItems.length > 0) {
          return false;
      }
      return true;
  }

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;
    //console.log(this.props);
    return (
      <div className={classes.MenuSection}>   
      <Button className={classes.newOrder} variant="contained" color="primary" disabled={this.isContinueButtonDisabled()} onClick={this.handleClickNewOrder}>
          Continue to Place Order
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth='md'
        >
            <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
                const props = {};
                const labelProps = {};
                return (
                <Step key={label} {...props}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
                );
            })}
            </Stepper>
            {this.renderDialogContent()}
      <Divider variant="middle" className={classes.dividerStyle}/>

          {this.renderDialogActions()}
        </Dialog>
      </div>
    );
  }
} // end Menu

NewOrder.propTypes = {
  //classes: PropTypes.object.isRequired,
  //orders: PropTypes.array.isRequired,
};


const mapStateToProps = ({state}) => {
  //console.log({state});
  return { orderItems: state.orderItems, orderTotal: state.orderTotal, orderSubtotal: state.orderSubtotal, orderTax: state.orderTax };
};

export default connect(mapStateToProps, { updateOrderItems })(withStyles(styles)(NewOrder));